/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
.site-main {
  padding-bottom: 30px; }

h1, h2, h3, h4 {
  font-family: "Open Sans", sans-serrif !important;
  font-weight: 600 !important; }

.front #main {
  max-width: none;
  margin: 0;
  padding: 0; }

.section {
  position: relative; }

.section__wrap {
  width: 80%;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .section__wrap {
      width: auto;
      max-width: 1140px;
      position: relative; } }

/**
 * ABOUT SECTION
 *
 */
.section--about {
  background: #fff; }
  .section--about::after {
    display: block;
    content: "";
    clear: both; }
  @media screen and (min-width: 992px) {
    .section--about .section__wrap {
      height: 550px; } }

.tree {
  width: 280px;
  height: 234px;
  margin: 1em auto;
  background-image: url(images/tree.png);
  -webkit-background-size: 280px 234px;
  -moz-background-size: 280px 234px;
  -o-background-size: 280px 234px;
  background-size: 280px 234px;
  background-repeat: no-repeat; }
  @media screen and (min-width: 768px) {
    .tree {
      width: 351px;
      height: 293px;
      float: left;
      margin: 1em 0;
      background-image: url(images/tree.png);
      -webkit-background-size: 351px 293px;
      -moz-background-size: 351px 293px;
      -o-background-size: 351px 293px;
      background-size: 351px 293px;
      background-repeat: no-repeat; } }
  @media screen and (min-width: 992px) {
    .tree {
      position: absolute;
      top: 20px;
      left: -125px;
      width: 701px;
      height: 586px;
      z-index: 100;
      background-image: url(images/tree.png);
      -webkit-background-size: 701px 586px;
      -moz-background-size: 701px 586px;
      -o-background-size: 701px 586px;
      background-size: 701px 586px;
      background-repeat: no-repeat; } }

@media screen and (min-width: 992px) {
  .leaves {
    position: absolute;
    top: 20px;
    right: 0;
    width: 673px;
    height: 352px;
    margin-top: 170px;
    z-index: 1;
    background-image: url(images/leaves.png);
    -webkit-background-size: 673px 352px;
    -moz-background-size: 673px 352px;
    -o-background-size: 673px 352px;
    background-size: 673px 352px;
    background-repeat: no-repeat; } }

@media screen and (min-width: 992px) {
  .leaves--alt {
    position: absolute;
    top: 110px;
    right: 100px;
    width: 500px;
    height: 158px;
    opacity: 1;
    margin-top: 150px;
    background-image: url(images/leaves-alt.png);
    -webkit-background-size: 500px 158px;
    -moz-background-size: 500px 158px;
    -o-background-size: 500px 158px;
    background-size: 500px 158px;
    background-repeat: no-repeat; } }

.intro {
  width: 80%;
  margin: 0 auto;
  z-index: 99999; }
  @media screen and (min-width: 768px) {
    .intro {
      float: right;
      width: 40%;
      padding: 160px 150px 0 0; } }
  @media screen and (min-width: 992px) {
    .intro {
      position: absolute;
      bottom: 0%;
      right: 0;
      width: 49%;
      top: 0; } }
  .intro h2 {
    margin-bottom: 0;
    color: #cf4520;
    font-style: italic;
    font-family: "Open Sans", sans-serif;
    font-weight: 400 !important;
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 34px; }
  .intro p {
    font-size: 18px;
    line-height: 28px; }

/* Extra breakpoint to smooth out odd jumps ----------- */
@media only screen and (min-width: 600px) and (max-width: 755px) {
  .intro {
    width: 100%;
    padding: 0; } }

@media only screen and (min-width: 756px) and (max-width: 984px) {
  .intro {
    width: 49%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 55px 0px 0px 0px; } }

@media only screen and (max-width: 860px) {
  .intro {
    width: 100%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 0px; } }

/**
 * TREATMENT SECTION
 *
 */
.section--treatment {
  background: #8a8c3b;
  color: #fff;
  padding: 2em 0 0; }
  .section--treatment::after {
    display: block;
    content: "";
    clear: both; }
  @media screen and (min-width: 992px) {
    .section--treatment {
      padding: 6em 0; } }

.treatment-intro {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .treatment-intro {
      font-size: 20px; } }

.service {
  text-align: center;
  padding: 0 0 1em; }
  @media screen and (min-width: 992px) {
    .service {
      float: left;
      width: 33%;
      padding: 2em 0 0 2em; } }
  .service h2 {
    line-height: 1.0;
    padding-bottom: .5em;
    color: #fff; }

.service--communication {
  padding-left: 0; }

.service__icon {
  display: block;
  margin: 0 auto 1em; }

.service__icon--communication {
  width: 123px;
  height: 110px;
  background-image: url(images/communication.png);
  -webkit-background-size: 123px 110px;
  -moz-background-size: 123px 110px;
  -o-background-size: 123px 110px;
  background-size: 123px 110px;
  background-repeat: no-repeat; }

.service__icon--quality {
  width: 181px;
  height: 114px;
  background-image: url(images/quality.png);
  -webkit-background-size: 181px 114px;
  -moz-background-size: 181px 114px;
  -o-background-size: 181px 114px;
  background-size: 181px 114px;
  background-repeat: no-repeat; }

.service__icon--planning {
  width: 127px;
  height: 119px;
  background-image: url(images/planning.png);
  -webkit-background-size: 127px 119px;
  -moz-background-size: 127px 119px;
  -o-background-size: 127px 119px;
  background-size: 127px 119px;
  background-repeat: no-repeat; }

/**
 * Image Callouts
 *
 */
.section--callouts {
  overflow: hidden; }
  .section--callouts::after {
    display: block;
    content: "";
    clear: both; }

.image-callout {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative; }
  @media screen and (min-width: 768px) {
    .image-callout {
      width: 50%;
      height: 0;
      padding-top: 52.50865052%;
      /* (img-height / img-width * width) = (607 / 578 * 50) */
      float: left; } }

.image-callout__text {
  position: absolute;
  bottom: 3%;
  right: 5%;
  width: 80%;
  color: #fff;
  text-align: right;
  font-size: 18px; }
  @media screen and (min-width: 992px) {
    .image-callout__text {
      width: 60%; } }
  .image-callout__text h2 {
    color: #fff;
    line-height: 1;
    padding-bottom: .25em;
    font-size: 32px; }

.image-callout--grief {
  background-image: url("images/grief.jpg"); }

.image-callout--care {
  background-image: url("images/care.jpg"); }
  .image-callout--care .image-callout__text {
    top: 3%;
    left: 5%;
    text-align: left; }

/**
 * Research Section
 *
 */
.section--research {
  background-color: #f6f5f6;
  margin: 0 0;
  padding: 0; }
  .section--research::after {
    display: block;
    content: "";
    clear: both; }
  @media screen and (min-width: 992px) {
    .section--research {
      margin: 0;
      padding: 0 0; } }
  .section--research h2 {
    color: #cf4520;
    margin-top: 0;
    font-size: 45px;
    text-align: center; }
  .section--research .research-ctas .views-row-first {
    border-bottom: 1px solid #fff;
    padding-bottom: 45px; }
  .section--research .research-ctas .views-row-last {
    padding-bottom: 45px; }
  .section--research .research-ctas h3 {
    line-height: 25px;
    color: #444;
    float: left;
    margin-top: 45px;
    padding-right: 20px;
    letter-spacing: 2.5pt; }
    @media screen and (min-width: 768px) {
      .section--research .research-ctas h3 {
        width: 40%; } }
  .section--research .research-ctas .research__description {
    display: block;
    float: left;
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 28px;
    color: #555; }
    @media screen and (min-width: 768px) {
      .section--research .research-ctas .research__description {
        width: 60%; } }
  .section--research .research-ctas .btn {
    font-size: 13px;
    font-weight: bold;
    clear: both;
    display: block;
    width: 120px;
    background-color: #fff;
    margin: 0;
    padding: 0 20px;
    border-radius: 8px; }
    @media screen and (min-width: 768px) {
      .section--research .research-ctas .btn {
        margin: 10px 0 0 40%; } }
    .section--research .research-ctas .btn:hover, .section--research .research-ctas .btn:focus {
      color: #fff;
      background-color: #cf4520; }
  .section--research p {
    max-width: 575px;
    margin: 0 auto 40px;
    display: block;
    font-size: 18px; }
  @media screen and (min-width: 992px) {
    .section--research .section__wrap {
      width: 70%;
      margin: 0 auto; } }
  .section--research .btn--wcm {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 70px; }
    .section--research .btn--wcm:before {
      line-height: 40px; }

.research-icon {
  position: relative;
  text-align: center;
  top: -64px;
  margin: 0 auto;
  display: block;
  background-color: #fff;
  width: 128px;
  height: 128px;
  border-radius: 50%; }
  .research-icon svg {
    top: 30px;
    position: relative; }

.icon {
  color: #cf4520;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: #e7751d;
  width: 72px;
  height: 72px; }

/**
 * FACULTY SECTION
 *
 */
.section--faculty {
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 0 0;
  clear: both; }
  .section--faculty .section__wrap {
    max-width: 815px;
    background-color: #fff;
    border-top: 8px solid #e7751d;
    border-radius: 14px;
    top: -60px; }
  .section--faculty::after {
    display: block;
    content: "";
    clear: both; }
  .section--faculty p {
    font-size: 18px;
    line-height: 29px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .section--faculty p {
        padding: 50px 195px; } }
    .section--faculty p a {
      color: #555;
      font-weight: 600; }
      .section--faculty p a:hover {
        color: #e7751d; }
      .section--faculty p a:after {
        content: '\e80d';
        color: #ffc72c;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        text-decoration: none;
        line-height: 2.75; }

.faculty-callout {
  padding: 0 0; }
  @media screen and (min-width: 992px) {
    .faculty-callout:first-child {
      padding-right: 84px; } }
  @media screen and (min-width: 992px) {
    .faculty-callout:last-child {
      padding-left: 95px; } }
  @media screen and (min-width: 768px) {
    .faculty-callout {
      width: 50%;
      float: left;
      padding: 0 25px;
      background-color: #fff; } }
  .faculty-callout a {
    float: left;
    width: 100%; }

.faculty-callout__image {
  width: 275px;
  height: 275px;
  border-radius: 50%;
  float: none;
  margin: 0 auto; }
  @media screen and (min-width: 768px) {
    .faculty-callout__image {
      float: left;
      margin: 0; } }

.faculty-callout__name {
  font-size: 20px;
  font-weight: bold;
  margin: 12px 0 5px;
  color: #cf4520;
  text-align: left;
  margin-top: 20px;
  float: left;
  width: 100%; }
  .faculty-callout__name a {
    float: left;
    width: 100%; }
    .faculty-callout__name a:hover {
      text-decoration: none; }
    .faculty-callout__name a:after {
      content: " ";
      clear: both;
      display: block;
      background-color: #e7751d;
      height: 5px;
      width: 40px;
      border-radius: 2.5px;
      -webkit-transition: width 1s ease-in-out;
      -moz-transition: width 1s ease-in-out;
      -o-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out; }

.hovbor:after {
  width: 240px !important;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out; }

.faculty-callout__title {
  font-size: 18px;
  line-height: 28px;
  max-width: 360px;
  text-align: left;
  margin-bottom: 65px;
  color: #555; }

/**
 * GRIEF INTENSITY SECTION
 *
 */
.section--gis {
  background-color: #f7f7f7;
  padding-bottom: 140px; }
  .section--gis h1 {
    color: #cf4520;
    text-align: center;
    padding-top: 115px;
    padding-bottom: 30px;
    font-size: 45px; }
  .section--gis p {
    text-align: center;
    max-width: 520px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 45px; }
  .section--gis a.btn {
    margin: 0 auto;
    background-color: #f7f7f7;
    padding: 10px 0; }
    .section--gis a.btn:hover {
      color: #b31b1b; }
      .section--gis a.btn:hover:before {
        color: #b31b1b;
        font-size: 160%; }
    .section--gis a.btn:focus {
      color: #b31b1b; }

/**
 * NEWS SECTION
 *
 */
.section--news::after {
  display: block;
  content: "";
  clear: both; }

@media screen and (min-width: 992px) {
  .section--news {
    padding-top: 2em;
    padding-bottom: 0; } }

.section--news h4.pane-title {
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  max-width: 520px;
  margin: 70px auto 60px;
  color: #cf4520; }

.section--newsevnets {
  margin-top: 160px;
  background-color: #f5f6f7; }
  .section--newsevnets .container {
    padding: 0; }
    @media screen and (min-width: 992px) {
      .section--newsevnets .container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center; } }
  .section--newsevnets .col-md-6 {
    top: -60px;
    padding: 0; }
    @media screen and (min-width: 992px) {
      .section--newsevnets .col-md-6:first-child {
        padding-left: 20px;
        padding-right: 20px; }
      .section--newsevnets .col-md-6:nth-child(2) {
        padding-right: 20px;
        padding-left: 20px; } }
    @media screen and (min-width: 1200px) {
      .section--newsevnets .col-md-6:first-child {
        padding-left: 0;
        padding-right: 20px; }
      .section--newsevnets .col-md-6:nth-child(2) {
        padding-right: 0;
        padding-left: 20px; } }
  .section--newsevnets h3 {
    color: #cf4520;
    font-size: 25px; }
  .section--newsevnets .views-row-first {
    border-bottom: 1px solid #e0e1e2;
    margin-bottom: 20px;
    min-height: 252px; }
  .section--newsevnets .featured-teaser__snippet {
    padding-bottom: 20px;
    padding-top: 12px; }
  .section--newsevnets .featured-teaser__date,
  .section--newsevnets .views-field-created {
    color: #707172;
    margin-bottom: 10px;
    font-size: 13px; }
  .section--newsevnets .pane-news-panel-pane-4 {
    margin-bottom: 20px; }
    @media screen and (min-width: 992px) {
      .section--newsevnets .pane-news-panel-pane-4 {
        margin-bottom: 0; } }
  .section--newsevnets .pane-news-panel-pane-4,
  .section--newsevnets .pane-news-panel-pane-7 {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    overflow: hidden;
    background-color: #fff; }
    @media screen and (min-width: 992px) {
      .section--newsevnets .pane-news-panel-pane-4,
      .section--newsevnets .pane-news-panel-pane-7 {
        order: 1;
        height: 635px; } }
    .section--newsevnets .pane-news-panel-pane-4 h3.pane-title,
    .section--newsevnets .pane-news-panel-pane-7 h3.pane-title {
      border-top: 9px solid #e7751d;
      width: 100%;
      margin-top: 0; }
    .section--newsevnets .pane-news-panel-pane-4 h3,
    .section--newsevnets .pane-news-panel-pane-7 h3 {
      padding: 0 35px;
      margin-top: 31px;
      margin-bottom: 11px; }
    .section--newsevnets .pane-news-panel-pane-4 .view-display-id-panel_pane_7,
    .section--newsevnets .pane-news-panel-pane-4 .view-display-id-panel_pane_4,
    .section--newsevnets .pane-news-panel-pane-7 .view-display-id-panel_pane_7,
    .section--newsevnets .pane-news-panel-pane-7 .view-display-id-panel_pane_4 {
      padding: 0 35px 40px; }

footer {
  padding-top: 0 !important; }
