// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

@mixin background-image($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  -webkit-background-size: $width $height;
  -moz-background-size: $width $height;
  -o-background-size: $width $height;
  background-size: $width $height;
  background-repeat: no-repeat;
}

.site-main {
  padding-bottom: 30px;
}

h1,h2,h3,h4 {
  font-family: "Open Sans", sans-serrif!important;
  font-weight: 600!important;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.front #main {
  max-width: none;
  margin: 0;
  padding: 0;
}

.section {
  position: relative;
}

.section__wrap {
  width: 80%;
  margin: 0 auto;
  @include breakpoint($md) {
    width: auto;
    max-width: 1140px;
    position: relative;
  }
}

/**
 * ABOUT SECTION
 *
 */

.section--about {
  background: #fff;
  @include clearfix();
  .section__wrap {
    @include breakpoint($md) {
      height: 550px;
    }
  }
}

.tree {
  width: 280px;
  height: 234px;
  margin: 1em auto;
  @include background-image(images/tree, png, 280px, 234px);
  @include breakpoint($sm) {
    width: 351px;
    height: 293px;
    float: left;
    margin: 1em 0;
    @include background-image(images/tree, png, 351px, 293px);
  }
  @include breakpoint($md) {
    position: absolute;
    top: 20px;
    left: -125px;
    width: 701px;
    height: 586px;
    z-index: 100;
    @include background-image(images/tree, png, 701px, 586px);
  }
}

.leaves {
  @include breakpoint($md) {
    position: absolute;
    top: 20px;
    right: 0;
    width: 673px;
    height: 352px;
    margin-top: 170px;
    z-index: 1;
    @include background-image(images/leaves, png, 673px, 352px);
  }
}

.leaves--alt {
  @include breakpoint($md) {
    position: absolute;
    top: 110px;
    right: 100px;
    width: 500px;
    height: 158px;
    opacity: 1;
    margin-top: 150px;
    @include background-image(images/leaves-alt, png, 500px, 158px);
  }
}

.intro {
  width: 80%;
  margin: 0 auto;
  z-index: 99999;
  @include breakpoint($sm) {
    float: right;
    width: 40%;
    padding: 160px 150px 0 0;
  }
  @include breakpoint($md) {
    position: absolute;
    bottom: 0%;
    right: 0;
    width: 49%;
    top: 0;
  }
  h2 {
    margin-bottom: 0;
    color: $wcm-dark-orange;
    font-style: italic;
    font-family: "Open Sans", sans-serif;
    font-weight: 400!important;
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 34px;
  }
  p{
    font-size: 18px;
    line-height: 28px;
  }
}

/* Extra breakpoint to smooth out odd jumps ----------- */
@media only screen
and (min-width : 600px)
and (max-width : 755px) {
  .intro {
    width: 100%;
    padding: 0;
  }
}

@media only screen
and (min-width : 756px)
and (max-width : 984px) {
  .intro {
    width: 49%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 55px 0px 0px 0px;
  }
}

@media only screen
and (max-width : 860px) {
  .intro {
    width: 100%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 0px;
  }
}

/**
 * TREATMENT SECTION
 *
 */

.section--treatment {
  background: #8a8c3b;
  color: #fff;
  padding: 2em 0 0;
  @include clearfix();
  @include breakpoint($md) {
    padding: 6em 0;
  }
}

.treatment-intro {
  text-align: center;
  @include breakpoint($md) {
    font-size: 20px;
  }
}

.service {
  text-align: center;
  padding: 0 0 1em;
  @include breakpoint($md) {
    float: left;
    width: 33%;
    padding: 2em 0 0 2em;
  }
  h2 {
    line-height: 1.0;
    padding-bottom: .5em;
    color: #fff;
  }
}

.service--communication {
  padding-left: 0;
}

.service__icon {
  display: block;
  margin: 0 auto 1em;
}

.service__icon--communication {
  width: 123px;
  height: 110px;
  @include background-image(images/communication, png, 123px, 110px);
}

.service__icon--quality {
  width: 181px;
  height: 114px;
  @include background-image(images/quality, png, 181px, 114px);
}

.service__icon--planning {
  width: 127px;
  height: 119px;
  @include background-image(images/planning, png, 127px, 119px);
}

/**
 * Image Callouts
 *
 */


.section--callouts {
  @include clearfix();
  overflow: hidden;
}

.image-callout {
  // http://stackoverflow.com/questions/600743/how-to-get-div-height-to-auto-adjust-to-background-size
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  @include breakpoint($sm) {
    width: 50%;
    height: 0;
    padding-top: 52.50865052%; /* (img-height / img-width * width) = (607 / 578 * 50) */
    float: left;
  }
}

.image-callout__text {
  position: absolute;
  bottom: 3%;
  right: 5%;
  width: 80%;
  color: #fff;
  text-align: right;
  font-size: 18px;
  @include breakpoint($md) {
    width: 60%;
  }
  h2 {
    color: #fff;
    line-height: 1;
    padding-bottom: .25em;
    font-size: 32px;
  }
}

.image-callout--grief {
  background-image: url('images/grief.jpg');
}

.image-callout--care {
  background-image: url('images/care.jpg');
  .image-callout__text {
    top: 3%;
    left: 5%;
    text-align: left;
  }
}

/**
 * Research Section
 *
 */

.section--research {
  background-color: #f6f5f6;
  margin: 0 0 ;
  padding:  0;
  @include clearfix();
  @include breakpoint($md) {
    margin: 0;
    padding: 0 0;
  }
  h2{
    color: $wcm-dark-orange;
    margin-top: 0;
    font-size: 45px;
    text-align: center;
  }

  .research-ctas{
    .views-row-first{
      border-bottom: 1px solid #fff;
      padding-bottom: 45px;
    }
    .views-row-last{
      padding-bottom: 45px;
    }
    h3{
      line-height: 25px;
      color: #444;
      float: left;
      margin-top: 45px;
      padding-right: 20px;
      letter-spacing: 2.5pt;
      @include breakpoint($sm){
        width: 40%;
      }
    }
    .research__description{
      display: block;
      float: left;
      margin-top: 40px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 28px;
      color: #555;
      @include breakpoint($sm){
        width: 60%;
      }
    }
    .btn{
      font-size: 13px;
      font-weight: bold;
      clear: both;
      display: block;
      width: 120px;
      background-color: #fff;
      margin: 0;
      padding: 0 20px;
      border-radius: 8px;
      @include breakpoint($sm){
         margin: 10px 0 0 40%;
      }
      &:hover, &:focus{
        color: #fff;
        background-color: $wcm-dark-orange;
      }
    }
  }

  p{
    max-width: 575px;
    margin: 0 auto 40px;
    display: block;
    font-size: 18px;
  }

  .section__wrap {
    @include breakpoint($md) {
      width: 70%;
      margin: 0 auto;
    }
  }
  .btn--wcm {
    padding-top: 10px;
    padding-bottom: 10px;
    &:before{
      line-height: 40px;
    }
    margin-bottom: 70px;
  }
}

.research-icon {
  position: relative;
  text-align: center;
  top: -64px;
  margin: 0 auto;
  display: block;
  background-color: #fff;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  svg{
    top: 30px;
    position: relative;
  }
}

.icon{
  color: $wcm-dark-orange;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: $wcm-bright-orange;
  width: 72px;
  height: 72px;
}


/**
 * FACULTY SECTION
 *
 */

.section--faculty {
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 0 0;
  clear: both;
  .section__wrap{
    max-width: 815px;
    background-color: #fff;
    border-top: 8px solid $wcm-bright-orange;
    border-radius: 14px;
    top: -60px;
  }
  @include clearfix();
  @include breakpoint($md) {
    //padding: 5em 0;
  }
  p{
    font-size: 18px;
    line-height: 29px;
    padding-top: 50px;
    padding-bottom: 50px;
    @include  breakpoint($md){
      padding: 50px 195px;
    }
    width: 100%;
    background-color: #fff;
    margin-bottom: 0;
    a {
      color: #555;
      font-weight: 600;
      &:hover{
        color: $wcm-bright-orange;
      }
      &:after{
        content: '\e80d';
        color: $wcm-yellow;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        text-decoration: none;
        line-height: 2.75;
      }
    }
  }
}

.faculty {
  @include breakpoint($sm) {

  }
}

.faculty-callout {
  padding: 0 0 ;

   &:first-child{
    @include breakpoint($md) {
      padding-right: 84px;
    }
  }

  &:last-child{
    @include breakpoint($md) {
      padding-left: 95px;
    }
  }

  @include breakpoint($sm) {
    width: 50%;
    float: left;
    padding: 0 25px;
    background-color: #fff;
  }
  a{
    float: left;
    width: 100%;
  }
}

.faculty-callout__image {
  width: 275px;
  height: 275px;
  border-radius: 50%;
  float: none;
  margin: 0 auto;
  @include breakpoint($sm){
    float: left;
    margin: 0;
  }
}

.faculty-callout__name {
  a{
    float: left;
    width: 100%;
    &:hover{
      text-decoration: none;
    }
    &:after{
      content: " ";
      clear: both;
      display: block;
      background-color: $wcm-bright-orange;
      height: 5px;
      width: 40px;
      border-radius: 2.5px;
      -webkit-transition: width 1s ease-in-out;
      -moz-transition: width 1s ease-in-out;
      -o-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;
    }
  }
  font-size: 20px;
  font-weight: bold;
  margin: 12px 0 5px;
  color: $wcm-dark-orange;
  text-align: left;
  margin-top: 20px;
  float: left;
  width: 100%;
}

.hovbor{
  &:after{
    width: 240px!important;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }
}

.faculty-callout__title{
  font-size: 18px;
  line-height: 28px;
  max-width: 360px;
  text-align: left;
  margin-bottom: 65px;
  color: #555;
}

/**
 * GRIEF INTENSITY SECTION
 *
 */

.section--gis {
  background-color: #f7f7f7;
  padding-bottom: 140px;
  h1{
    color: $wcm-dark-orange;
    text-align: center;
    padding-top: 115px;
    padding-bottom: 30px;
    font-size: 45px;
  }
  p{
    text-align: center;
    max-width: 520px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 45px;
  }
  a.btn{
    margin: 0 auto;
    background-color: #f7f7f7;
    padding: 10px 0;
    &:hover{
      color: $wcm-red;
      &:before{
        color: $wcm-red;
        font-size: 160%;
      }
    }
    &:focus{
      color: $wcm-red;
    }
  }
}

/**
 * NEWS SECTION
 *
 */

.section--news {
  //background-color: #f7f7f7;
  @include clearfix();
  @include breakpoint($md) {
    padding-top: 2em;
    padding-bottom: 0;
  }

  h4.pane-title{
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    max-width: 520px;
    margin: 70px auto 60px;
    color: $wcm-dark-orange;
  }

}

.section--newsevnets {
  margin-top: 160px;
  background-color: #f5f6f7;
  @include breakpoint($md){
    //max-height: 600px;
  }
  .container {
    padding: 0;

    @include breakpoint($md) {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
    }


    @include breakpoint($md){
      //max-height: 600px;
    }
  }

  .col-md-6{
    top: -60px;
    padding: 0;

    @include breakpoint($md){
      &:first-child{
        padding-left: 20px;
        padding-right: 20px
      }
      &:nth-child(2){
        padding-right: 20px;
        padding-left: 20px
      }
    }

    @include breakpoint($lg){
      &:first-child{
        padding-left: 0;
        padding-right: 20px
      }
      &:nth-child(2){
        padding-right: 0;
        padding-left: 20px
      }
    }
  }

  h3 {
    color: $wcm-dark-orange;
    font-size: 25px;
  }

  .views-row-first {
    border-bottom: 1px solid #e0e1e2;
    margin-bottom: 20px;
    min-height: 252px;
  }

  .featured-teaser__snippet {
    padding-bottom: 20px;
    padding-top: 12px;
  }

  .featured-teaser__date,
  .views-field-created {
    color: #707172;
    margin-bottom: 10px;
    font-size: 13px;
  }

  .pane-news-panel-pane-4 {
    margin-bottom: 20px;
    @include breakpoint($md) {
      margin-bottom: 0;
    }
  }
  .pane-news-panel-pane-4,
  .pane-news-panel-pane-7 {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    overflow: hidden;
    background-color: #fff;

    @include breakpoint($md) {
      order: 1;
      height: 635px;
    }

    h3.pane-title {
      border-top: 9px solid $wcm-bright-orange;
      width: 100%;
      margin-top: 0;
    }
    h3 {
      padding: 0 35px;
      margin-top: 31px;
      margin-bottom: 11px;
    }
    .view-display-id-panel_pane_7,
    .view-display-id-panel_pane_4 {
      padding: 0 35px 40px;
    }
  }
}

.section--newsevnets-bottom {}
.section--footer {}

footer {
  padding-top: 0 !important;
}
